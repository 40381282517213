import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable, of, pluck, tap } from 'rxjs';
import { LocaleService } from '@shared/services/locale.service';
import { TranslocoService } from '@ngneat/transloco';
import { WINDOW_NAVIGATOR_LANGUAGES } from '@lib/window';
import { SideMenuService } from '@shared/services/side-menu.service';
import { AuthService, TypeAuthRoles } from '@app/auth';
import { PusherService } from '@shared/services/pusher.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToasterService } from '@shared/components/toaster/toaster.service';
import { ChatboxPosition, Crisp } from "crisp-sdk-web";
import { CRIPS_WEBSITE_ID, LocalStorageService, OrganizationStorage, PARTNER_KEY } from '@app/shared';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  providers: [AsyncPipe]
})
export class MainContainerComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  isExpanded = false;
  public readonly userRoles$: Observable<TypeAuthRoles> = this._authService.currentUserRoles$;
  public readonly language: BehaviorSubject<string> = new BehaviorSubject<string>('lang');
  chatOpened = false;
  private _organizationStorage = inject(OrganizationStorage);
  private asyncPipe = inject(AsyncPipe);
  partnerList = this._authService.partnerList;

  public readonly navMenu = [
    {
      name: 'NAVIGATION.DASHBOARD',
      link: '/dashboard',
      iconPath: 'home-stats1.svg#home-svg',
      isAccess$: of(true)
    },
    {
      name: 'NAVIGATION.LOYALTY',
      link: '/loyalty',
      iconPath: 'shopping-cart-discount1.svg#shop-svg',
      isAccess$: this._authService.loyaltyAccess$
    },
    {
      name: 'NAVIGATION.DELIVERY',
      link: '/delivery',
      iconPath: 'building-store1.svg#build-svg',
      isAccess$: this._authService.deliveryAccess$
    },
    {
      name: 'NAVIGATION.PRODUCTS',
      link: '/product/list',
      iconPath: 'product_nav.svg#product_nav',
      isAccess$: this._authService.cmsAccess$
    },
    {
      name: 'NAVIGATION.CLIENTS',
      link: '/clients/list',
      iconPath: 'users1.svg#user-svg',
      isAccess$: this._authService.crmAccess$
    },
    {
      name: 'NAVIGATION.OMS',
      link: '/orders',
      iconPath: 'receipt-2.svg#receipt-svg',
      isAccess$: this._authService.cmsAccess$
    },
    {
      name: 'NAVIGATION.LOGISTIC',
      link: '/logistic',
      iconPath: 'radar.svg#radar-svg',
      isAccess$: this._authService.logisticAccess$
    },
    {
      name: 'NAVIGATION.CONFIGURATIONS',
      link: '/configurations',
      iconPath: 'adjustments-cog.svg#cog-svg',
      isAccess$: this._authService.canChangeConfigurations$
    },
    {
      name: 'Partners',
      link: '/partners',
      iconPath: 'adjustments-cog.svg#cog-svg',
      isAccess$: this._authService.partnersAccess$ 
    }
  ]
  public superAdmin$ = this._authService.superAdminPemision$.pipe(pluck('canRead'));

  constructor(
    private readonly _authService: AuthService,
    private readonly _localeService: LocaleService,
    private readonly _translocoService: TranslocoService,
    private readonly _pusherService: PusherService,
    private readonly localStore: LocalStorageService,
    private readonly toasterService: ToasterService,
    public sideService: SideMenuService,
    @Inject(WINDOW_NAVIGATOR_LANGUAGES) private browserLanguages: NavigatorLanguage['languages'],
  ) {
    // For all pusher subscribe methods;
    this._pusherService.subscribe();
   }

  public ngOnInit(): void {
    this.sideService.isExpanded$.subscribe(isExpanded=>{
      this.isExpanded = isExpanded;
    })
    if(!this.asyncPipe.transform(this.superAdmin$)){
      const partnerName = this._authService.tokenInfo.organizations.info[this._organizationStorage.orgId as string].name;
      Crisp.configure(CRIPS_WEBSITE_ID, {
        autoload: false
      });
      Crisp.session.setSegments(['partner'], true);
      Crisp.setPosition('left' as ChatboxPosition);
      Crisp.user.setEmail(this._authService.tokenInfo.email)
      Crisp.user.setNickname(this._authService.tokenInfo.name)
      Crisp.session.setData({
          partnerId: this._organizationStorage.partnerId,
          partnerName: partnerName
      });
      Crisp.chat.close()
      Crisp.session.onLoaded(()=>{
        document.querySelector('.cc-1m2mf')?.classList.add('invisible')
      })
    }
    this._initializeLocale();
    this.subscribeOnNewOrders();
    this.subscribeOnImportsCatalogChanged();
  }

  toggleCrispChat(): void {
    if (this.chatOpened) {
      this.chatOpened = false;
      Crisp.chat.close();
    } else {
      this.chatOpened = true;
      Crisp.chat.open();
    }
  }

  private subscribeOnImportsCatalogChanged(): void {
    this._pusherService.importsCatalogChanged().pipe(
       tap((catalog) => {
        if (catalog) {
          if(!this.asyncPipe.transform(this.superAdmin$)){
            this.toasterService.show(`Items has been imported from Shopify`);
          }
          else{
            const partnerId: string = this.localStore.get(PARTNER_KEY) as string;
            const partner = this.partnerList.find(partner=> partner.id === partnerId);
            this.toasterService.show(`Items has been imported from Shopify for ${partner?.name}`);
          }
        }
      }),
      untilDestroyed(this),
  ).subscribe();
  }

  private subscribeOnNewOrders(): void {
    this._pusherService.newOrders().pipe(
        pluck('order'),
        tap((newOrder) => {          
          if (newOrder) {
            this.toasterService.show(`<span>You have a new order</span><br><span>${newOrder?.number}</span>`, `orders/order/${newOrder?.id}`, true);
          }
        }),
        untilDestroyed(this),
    ).subscribe();
  }

  hideNav(){
    this.sideService.isExpanded$.next(false);
  }

  public logout(): void {
    this._authService.logout();
  }

  public switchLanguageHandler(lang: string): void {
    this._localeService.setLocale(lang);
  }

  private _initializeLocale(): void {
    const defaultLang = this._translocoService.getDefaultLang();
    const browserLang = this.browserLanguages.find((lang: string) =>
      this._translocoService.isLang(lang),
    );
    let storedLanguage = this._localeService.getCurrentLocale();

    if (storedLanguage && !this._translocoService.isLang(storedLanguage)) {
      storedLanguage = null;
    }

    const language = storedLanguage ?? browserLang ?? defaultLang ?? 'en';
    this._localeService.setLocale(language);
    this.language.next(language);
  }
}
