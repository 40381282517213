import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILanguage } from '@app/core/interfaces/language.interface';
import { LanguageConfig } from '@app/core/configs/language-config';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

interface LanguageMap {
  [key: string]: string;
}

@Component({
  selector: 'app-header-language',
  templateUrl: './header-language.component.html',
  styleUrls: ['./header-language.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'overlay-pane-style' }
    }
  ]

})
export class HeaderLanguageComponent {
   public activeLanguage = 'en';
  // @Input()
  // public activeLanguage = 'en';

  // todo : move to translations
  public languageMap: LanguageMap = {
    en: 'English',
    uk: 'Українська',
    ru: 'Русский',
  };

  @Input()
  public inline = false;
  @Input()
  public isExpanded = false;

  @Output()
  public readonly switchLanguageEvent = new EventEmitter<string>();

  public languages: ILanguage  = LanguageConfig.languages;

  public switchLanguage(value: string): void {
    if (this.activeLanguage !== value) {
      this.activeLanguage = value;
    }

    this.switchLanguageEvent.emit(value);
  }
}
